import React from 'react';
import {graphql} from 'gatsby';
import Page from '../components/Page';

const Category = ({data}) => {
  return (
    <Page
      title={data.category.title}
      description={`Get the latest ${data.category.title.toLowerCase()} news from readss.`}
      path={data.category.path}
      feedItems={data.category.childrenFeedItem}
      searchPages={data.localSearchPages}
      breadcrumb="/categories"
    />
  );
};

export default Category;

export const FEED_ITEM_FRAGMENT = graphql`
  fragment FeedItemFragmentFeed on FeedItem {
    id
    pubDate
    title
    link
    author
    content
    ttr
    parent {
      ... on Feed {
        title
        path
        twitter
      }
    }
  }
`;

export const query = graphql`
  query CategoryQuery($id: String) {
    category(id: {eq: $id}) {
      id
      title
      path
      childrenFeedItem {
        ...FeedItemFragmentFeed
      }
    }
  }
`;
